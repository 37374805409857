import React from 'react';
import styled from 'styled-components';

export default function EngLayout(props) {
  return <Wrapper>{props.children}</Wrapper>;
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  min-height: var(--contentMinHeight);
`;
