import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import NavLink from '../NavLink';
import * as AppActions from '../../AppActions';
import {PersonCircle} from '@styled-icons/bootstrap/PersonCircle';
import {Search as SearchIcon} from '@styled-icons/bootstrap/Search';
import {Language as LanguageIcon} from '@styled-icons/material/Language';
import {ArrowRight as ArrowRightIcon} from '@styled-icons/bootstrap/ArrowRight';
import * as NavUtil from '../../Utils/NavUtil';
import Theme from '../../Theme';
import * as Ant from 'antd';
import * as User from 'rev.sdk.js/Actions/User';

const Config = require('../../../data.json');
const L = AppActions.L;
const F = AppActions.F;

export function NavItemsContent(props) {
  const {navItems, showLoginModal} = props;
  const [user] = useOutlet('user');

  return (
    <>
      <div className="nav-items twpaa-desktop-only">
        {navItems.map((navItem, idx) => {
          const selected = NavUtil.isNavLinkSelected(navItem);

          if (navItem.entries) {
            return (
              <DropDownMenu key={idx}>
                <button className="dropdown-btn" key={idx}>
                  {F(navItem, 'name')}
                </button>

                <div className="dropdown-content">
                  {(navItem.entries || []).map((item, i) => (
                    <NavLink
                      {...NavUtil.getNavLinkExtraProps(navItem)}
                      to={item.path}
                      key={i}>
                      {F(item, 'name')}
                    </NavLink>
                  ))}
                </div>
              </DropDownMenu>
            );
          }

          return (
            <NavLink
              {...NavUtil.getNavLinkExtraProps(navItem)}
              to={navItem.path}
              key={idx}
              className={selected ? `default selected` : `default`}>
              {F(navItem, 'name')}
            </NavLink>
          );
        })}
      </div>

      <button
        className="twpaa-nav-btn twpaa-desktop-only"
        onClick={() => AppActions.navigate('/eng/search/')}>
        <SearchIcon size={18} style={{marginRight: 4}} />
        Member Search
      </button>
      <button
        className="twpaa-nav-btn twpaa-desktop-only"
        onClick={() => AppActions.navigate('/')}>
        <LanguageIcon size={20} style={{marginRight: 4}} />
        中文
      </button>

      {user ? (
        <>
          <button
            className="nav-round-btn-box login twpaa-desktop-only"
            onClick={() => {
              AppActions.navigate('/profile');
            }}>
            <PersonCircle size={22} color={Theme.colors.main} />
            <div>PROFILE</div>
          </button>
          <Ant.Button
            type="link"
            onClick={() => {
              AppActions.setLoading(true);
              User.logout();
              setTimeout(() => {
                AppActions.navigate('/');
                AppActions.setLoading(false);
              }, 600);
            }}>
            LOGOUT
          </Ant.Button>
        </>
      ) : (
        <button
          className="nav-round-btn-box login twpaa-desktop-only"
          onClick={() => {
            showLoginModal(true);
          }}>
          <div>LOGIN</div>
          <ArrowRightIcon size={20} color={Theme.colors.main} />
        </button>
      )}
    </>
  );
}

const DropDownMenu = styled.div`
  position: relative;
  display: inline-block;

  & > .dropdown-btn {
    border: none;
    background-color: #ffffff;
    padding: 0px 10px;
    cursor: pointer;

    border-bottom: 6px solid transparent;
    margin: 0px 25px;
    color: #707070;
  }

  & > .dropdown-content {
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 101;
    background-color: #ffffff;
    min-width: 100px;
    overflow: hidden;

    transition: all 150ms linear 100ms;
    height: 0px;
    opacity: 0;

    & > a {
      display: block;
      line-height: 2.2;
      text-align: center;
    }
  }

  &:hover > .dropdown-btn {
    color: var(--secondColor);
  }

  &:hover > .dropdown-content {
    height: auto;
    padding: 15px 5px;
    opacity: 1;

    & > a:hover {
      color: var(--secondColor);
    }
  }
`;
