export function getAdminRoutes() {
  return [
    {name: '首頁', path: '/admin/'},
    {name: '網站設定', path: '/admin/site/'},
    {
      name: '會員管理',
      path: '__group-members',
      subroutes: [
        {name: '會員全覽', path: '/admin/users/'},
        {name: '新入會申請', path: '/admin/pending_registrations/'},
        {name: '進修時數紀錄', path: '/admin/study_hours/'},
        {name: '會費繳納紀錄', path: '/admin/member_fees/'},
      ],
    },
    {name: '活動全覽', path: '/admin/products/'},
    {name: '文章管理', path: '/admin/articles/'},
    {
      name: '官網管理',
      path: '__group-website',
      subroutes: [
        {name: '最新消息', path: '/admin/internal_news/'},
        {name: '理監事', path: '/admin/supervisors/'},
        {name: '理監事成員', path: '/admin/supervisor_members/'},
        {name: '委員會', path: '/admin/committees/'},
        {name: '委員會成員', path: '/admin/committee_members/'},
        {name: '委員會申請', path: '/admin/committee_member_applications/'},
        {name: '公會組織圖', path: '/admin/org_charts/'},
        {name: '專題講義', path: '/admin/speech_keynotes/'},
        {name: '活動花絮', path: '/admin/activities/'},
        {name: '徵才看板', path: '/admin/jobs/'},
        {name: '行政文件', path: '/admin/admin_documents/'},
        {name: '訴代專區', path: '/admin/law_services/'},
        {name: '專業榜', path: '/admin/honors/'},
        {name: '專利師季刊', path: '/admin/magazines/'},
        {name: '美國電子報', path: '/admin/us_magazines/'},
        {name: '其他檔案', path: '/admin/etc_files/'},
        {name: '會議記錄', path: '/admin/meetings/'},
        {name: '財務報表', path: '/admin/financial_reports/'},
        {name: '職前訓練', path: '/admin/trainings/'},
        {name: '社會參與', path: '/admin/social_participations/'},
      ],
    },
    {name: '溝通會意見表', path: '/admin/feedback_forms/'},
    // {name: '頁面', path: '/admin/pages'},
    // {
    //   name: '上傳工具',
    //   path: '__group-uploader',
    //   subroutes: [
    //     {name: '圖片上傳', path: 'image-uploader'},
    //     {name: '檔案上傳', path: 'file-uploader'},
    //   ],
    // },
    {name: '前台手動更新', path: '/admin/rebuild-history'},
    {name: '重設密碼', path: 'reset-password'},
    {name: '登出', path: 'logout'},
  ];
}
