import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import * as AppActions from '../../../AppActions';
import {Search as SearchIcon} from '@styled-icons/bootstrap/Search';
import * as User from 'rev.sdk.js/Actions/User';

export default function MobileNavDrawer(props) {
  const showLoginModal = useOutletSetter('login-modal');
  const {visible, setVisible} = props;
  const [user] = useOutlet('user');

  return (
    <>
      <Backdrop visible={visible} onClick={() => setVisible(false)} />
      <MobileContent visible={visible}>
        <div className="header">
          <button
            style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
            onClick={() => AppActions.navigate('/search/')}>
            <SearchIcon size={18} style={{marginRight: 3}} />
            <h3>Member Search</h3>
          </button>
        </div>

        {props.children}

        <div className="footer">
          {!user ? (
            <Ant.Button
              type="outline"
              size="large"
              style={{borderRadius: 100}}
              onClick={() => {
                showLoginModal(true);
                setVisible(false);
              }}>
              LOGIN
            </Ant.Button>
          ) : (
            <>
              <Ant.Button
                type="outline"
                size="large"
                style={{borderRadius: 100}}
                onClick={() => {
                  AppActions.navigate('/profile');
                  setVisible(false);
                }}>
                Member Services
              </Ant.Button>
              <Ant.Button
                type="link"
                onClick={() => {
                  AppActions.setLoading(true);
                  User.logout();
                  setTimeout(() => {
                    AppActions.navigate('/');
                    AppActions.setLoading(false);
                  }, 600);
                }}>
                Logout
              </Ant.Button>
            </>
          )}
        </div>
      </MobileContent>
    </>
  );
}

const Backdrop = styled.div`
  z-index: 101;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  transition: 300ms;
`;

const MobileContent = styled.div`
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 194px;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.31);
  display: flex;
  flex-direction: column;
  transition: 300ms;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: ${(props) =>
    props.visible ? 'translateX(0px)' : 'translateX(-200px)'};

  & > .header {
    display: flex;
    justify-content: space-between;
    padding: 14px;
    border-bottom: 1px solid #b4b4b4;

    & > h2 {
      font-weight: bold;
      color: var(--primaryColor);
    }

    & > button {
      border: 0px;
      background-color: transparent;
    }
  }

  & > .nav-items {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 250px);
    overflow: auto;

    & > * {
      &.default {
        padding: 14px 20px;
        font-size: 1rem;
        color: #646464;
      }

      &.default-child {
        padding: 14px 20px 14px 35px;
        font-size: 1rem;
        color: #646464;
      }

      &.selected {
        color: var(--primaryColor);
      }
    }
  }

  & > .footer {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    border-top: 1px solid #ccc;
  }
`;
