import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import TwpaaCategoryTree from '../../Components/TwpaaCategoryTree';

const MemberCategory = {
  items: [
    {
      name: '會員入會須知',
      display: '會員入會須知',
      url: '/member/notice/',
    },
    {
      name: '會員入會申請',
      display: '會員入會申請',
      url: '/member/register/',
    },
    {
      name: '會員福利',
      display: '會員福利',
      url: '/member/?id=66dfff99ffd236b7885b57ec',
    },
  ],
};

export default function MemberLayout(props) {
  return (
    <Wrapper>
      <div className="main">
        <div className="nav">
          <TwpaaCategoryTree root={MemberCategory} location={props.location} />
        </div>
        <div className="content">{props.children}</div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  min-height: var(--contentMinHeight);

  & > .main {
    max-width: 1120px;
    margin: 20px auto;
    display: flex;
    align-items: flex-start;
    gap: 20px;

    & > .nav {
      margin-top: 60px;
      width: 240px;
    }

    & > .content {
      flex: 1;
      min-height: 300px;
      padding: 0 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    & > .main {
      flex-direction: column;
      align-items: stretch;
      & > .nav {
        width: 100%;
        order: 10;
      }
    }
  }
`;
