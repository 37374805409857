import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import Theme from '../../Theme';

export default function SiteFooter(props) {
  useOutlet('navbarConfig');

  return (
    <Wrapper>
      <div className="content">
        <div className="row">
          <div className="left">
            <div className="logo-box">
              <img src="/images/site-logo.png" alt="Logo" />
              <div className="logo-text">
                <h3>Taiwan Patent Attorneys Association</h3>
                <div>
                  <div>
                    Address: 11F.-1, No. 508, Sec. 5, Zhongxiao E. Rd., Xinyi
                    Dist., Taipei City 110, Taiwan (R.O.C.)
                  </div>
                  <div>Tel: 886-2-2701-1990</div>
                  <div>Fax: 886-2-2701-0799</div>
                  <div>Email: mail@twpaa.org.tw</div>
                </div>
              </div>
            </div>
          </div>
          <div style={{marginTop: 30}}></div>
          <div className="right">
            <div>© 2025 TWPAA. All Rights Reserved.</div>
          </div>
        </div>

        <div className="bottom" />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-top: 2px solid #ddd;
  padding: 40px 20px;

  .content {
    max-width: 1200px;
    margin: 0 auto;

    .row {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      .left {
        flex: 1 0 300px;
      }

      .right {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
      }
    }

    .bottom {
      margin-top: 40px;
      border-top: 2px solid ${Theme.colors.main};
      padding: 30px 0;
    }
  }

  .logo-box {
    display: flex;
    gap: 10px;
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }

  .nav-items {
    h3 {
      margin-bottom: 10px;
    }

    ul {
      padding: 0px;
      & > li {
        list-style-type: none;
        display: block;
        margin-bottom: 5px;
        a {
          color: #666;
        }
      }
    }
  }
`;
