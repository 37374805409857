import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {showAdminSearchUserModal} from '../../Components/AdminSearchUserModal/index.js';
import {getNewOutlet, useOutlet} from 'reconnect.js';

const AllUsersOutlet = getNewOutlet('allUsers', [], {autoDelete: false});

export async function loadAllUsers() {
  try {
    AllUsersOutlet.update(await JStorage.fetchAllDocuments('user_profile', {}));
  } catch (ex) {
    console.warn('DBG fail to loadAllUsers', ex);
  }
}

export default function AdminSelectUserWidget(props) {
  const [allUsers] = useOutlet('allUsers');

  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;

  const selectedUser = allUsers.find((it) => it.owner === props.value);
  console.log('selectedUser', selectedUser);

  return (
    <Wrapper>
      <div>
        {!props.value ? (
          '尚未設定會員'
        ) : !selectedUser ? (
          props.value
        ) : (
          <Button
            type="link"
            target="_blank"
            title="點選開啟新分頁編輯"
            href={`/admin/users/?action=detail&id=${selectedUser.id}`}>{`【 ${(
            '0000' + selectedUser.member_id
          ).slice(-4)} / ${selectedUser.name} / ${selectedUser.phone} / ${
            selectedUser.email
          } 】`}</Button>
        )}
      </div>
      {!readonly && (
        <>
          <Button
            onClick={() => {
              props.onChange('');
              // setSelectedUser(null);
            }}
            style={{marginRight: 10}}>
            清除
          </Button>

          <Button
            type="primary"
            onClick={() =>
              showAdminSearchUserModal({
                onChange: (owner) => {
                  props.onChange(owner);
                },
              })
            }>
            搜尋
          </Button>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid #d9d9d9;
  padding: 15px;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;
