import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import TwpaaCategoryTree from '../../Components/TwpaaCategoryTree';

export default function InfoLayout(props) {
  const [infoCats] = useOutlet('twpaa_info_category');

  return (
    <Wrapper>
      <div className="main">
        <div className="nav">
          <TwpaaCategoryTree root={infoCats} location={props.location} />
        </div>
        <div className="content">{props.children}</div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  min-height: var(--contentMinHeight);

  & > .main {
    max-width: 1120px;
    margin: 20px auto;
    display: flex;
    align-items: flex-start;
    gap: 20px;

    & > .nav {
      margin-top: 60px;
      width: 240px;
    }

    & > .content {
      flex: 1;
      min-height: 300px;
      padding: 0 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    & > .main {
      flex-direction: column;
      align-items: stretch;
      & > .nav {
        width: 100%;
        order: 10;
      }
    }
  }
`;
