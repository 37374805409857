import {getOutlet} from 'reconnect.js';
import {navigate as nav} from 'gatsby';
import * as User from 'rev.sdk.js/Actions/User';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Admin from 'rev.sdk.js/Actions/Admin';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as PathUtil from 'rev.sdk.js/Utils/PathUtil';
import NavUrl from 'rev.sdk.js/Utils/NavUrl';
import Config from '../../data.json';
import * as jwt from 'rev.sdk.js/Utils/jwt';
import tracker from 'rev.sdk.js/Utils/Tracker';
import AppActionsOverride from './custom';
import * as Locale from '../Locale';
import * as Ant from 'antd';
import uuid4 from 'uuid/v4';

const req = ApiUtil.req;
const LoadingOutlet = getOutlet('loading');
const ApiHookOutlet = getOutlet('ApiUtil');
const UserOutlet = getOutlet('user');

ApiHookOutlet.update({
  ...ApiHookOutlet.getValue(),
  onJson: (url, payload, jsonResp) => {
    // a sample hook, you can do whatever you want here
    return jsonResp;
  },
  onError: async (url, payload, resp) => {
    if (url.indexOf('token=') > -1 && resp.status === 410) {
      console.log('onError try autoLogin');
      const user = UserOutlet.getValue();
      const isAdmin = user.grp.split(':').indexOf('admin') !== -1;
      const result = await User.autoLogin({admin: isAdmin});
      if (result) {
        console.log('onError autoLogin success, fetch resource again', result);
        return req(url, payload, {ignoreOnErrorHook: true});
      }
      console.log('onError autoLogin failure, throw original error', result);
      throw resp;
    }
  },
});

function delay(ms) {
  if (AppActionsOverride.delay) {
    return AppActionsOverride.delay(...arguments);
  }

  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function tryError(promiseOrFn, opts) {
  return new Promise((resolve) => {
    const {loading = true} = opts || {};
    if (loading) {
      setLoading(true);
    }

    if (typeof promiseOrFn === 'function') {
      promiseOrFn = promiseOrFn();
    }

    promiseOrFn
      .then((result) => {
        resolve([null, result]);
        if (loading) {
          setLoading(false);
        }
      })
      .catch((error) => {
        if (!error) {
          error = new Error('empty error obj');
        }
        resolve([error]);
        if (loading) {
          setLoading(false);
        }
      });
  });
}

function setLoading(loading, params) {
  if (AppActionsOverride.setLoading) {
    return AppActionsOverride.setLoading(...arguments);
  }

  const {message = ''} = params || {};
  setTimeout(() => {
    LoadingOutlet.update({loading: loading, message: message});
  }, 0);
}

function L(key, defaultValue) {
  return Locale.getLabel(key, defaultValue);
}

function F(obj, field) {
  return Locale.getField(obj, field);
}

function gtag(eventType, event, payload) {
  if (AppActionsOverride.gtag) {
    return AppActionsOverride.gtag(...arguments);
  }

  let shouldContinue = true;
  // when return true , would trigger the default fbq behavior in rev.sdk.js
  return shouldContinue;
}

function fbq(eventType, event, payload) {
  if (AppActionsOverride.fbq) {
    return AppActionsOverride.gtag(...arguments);
  }

  let shouldContinue = true;
  // when return true , would trigger the default fbq behavior in rev.sdk.js
  return shouldContinue;
}

async function navigate(nextRoute, options = {}) {
  if (AppActionsOverride.navigate) {
    return AppActionsOverride.navigate(...arguments);
  }

  const {message = '', loading = false, ...rest} = options;
  if (nextRoute instanceof NavUrl) {
    nextRoute = nextRoute.toString();
  }

  const currRoute = PathUtil.normalizedRoute();
  nextRoute = PathUtil.normalizedRoute(nextRoute);
  if (currRoute !== nextRoute) {
    if (options?.loading) {
      LoadingOutlet.update({
        message: options.message,
        loading: options.loading,
      });
      if (typeof options.loading === 'number') {
        setTimeout(() => {
          LoadingOutlet.update(false);
        }, options.loading);
      }
    }
    await nav(nextRoute, rest);
  } else {
    console.log('path not changed, ignore...');
  }
}

function adminCanAccess(user, options = {}) {
  if (AppActionsOverride.adminCanAccess) {
    return AppActionsOverride.adminCanAccess(...arguments);
  }

  return true;
}

/**
 * **************************************************
 * (client) JStorage powered product fetching APIs
 * **************************************************
 */

async function clientJStorageFetch(collection, {cat, sort, search, q, page}) {
  if (AppActionsOverride.clientJStorageFetch) {
    return AppActionsOverride.clientJStorageFetch(...arguments);
  }

  //"q" can defined custom query by project
  const catQuery = cat ? {labels: {$regex: cat}} : {};
  const sortValue = sort ? [sort] : ['-created'];
  const pagingValue = page;
  const extraQueries = {};
  let projection = null;

  if (collection === 'product') {
    extraQueries.public = true;
    extraQueries.archived = {$ne: true};
    extraQueries.type = {$ne: 'group_item'};

    if (!Config.disableAutoShelving) {
      extraQueries['$or'] = [
        {stock_type: {$exists: false}},
        {stock_type: 'always'},
        {stock_type: 'total'},
        {
          stock_type: 'period',
          stock_start_date: {
            $lte: new Date().toISOString().split('T')[0],
          },
          stock_end_date: {
            $gte: new Date().toISOString().split('T')[0],
          },
        },
      ];
    }

    if (search) {
      if (!extraQueries['$or']) {
        extraQueries['$or'] = [
          {title: {$regex: search}},
          {searchText: {$regex: search}},
        ];
      } else {
        extraQueries['$or'] = extraQueries['$or'].map((query) => {
          return {
            ...query,
            $or: [{name: {$regex: search}}, {searchText: {$regex: search}}],
          };
        });
      }
    }
  } else if (collection === 'Article_Default') {
    delete catQuery.labels;

    if (cat) {
      catQuery.labels = {$regex: cat};
    } else {
      catQuery.labels = 'blog';
    }

    if (search) {
      extraQueries['$or'] = [
        {title: {$regex: search}},
        {searchText: {$regex: search}},
      ];
    }

    projection = {content: 0};
  }

  const resp = await JStorage.fetchDocuments(
    collection,
    {
      ...catQuery,
      ...extraQueries,
    },
    sortValue,
    pagingValue,
    projection, // if we're fetching Article, ignore the content
    {anonymous: true},
  );

  return resp;
}

function getDefaultCheckoutFormSpec() {
  if (AppActionsOverride.getDefaultCheckoutFormSpec) {
    return AppActionsOverride.getDefaultCheckoutFormSpec(...arguments);
  }
  // TODO: deprecate AppActionsOverride.getDefaultCheckoutFormSpec
  // customize from config/custom.js

  return {
    paymentSubTypes: Config.paymentSubTypes,
    logisticsTypes: Config.logisticsTypes,
    logisticsSubTypes: Config.logisticsSubTypes,
    invoiceCategories: Config.invoiceCategories,
    invoiceTypes: Config.invoiceTypes,
    invoiceCarrierTypes: Config.invoiceCarrierTypes,
  };
}

function onCartLoaded(cart) {
  if (AppActionsOverride.onCartLoaded) {
    return AppActionsOverride.onCartLoaded(...arguments);
  }

  const checkoutFormSpec = getDefaultCheckoutFormSpec();

  const defaultUser = {
    buyer_name: cart.buyer_name || UserOutlet.getValue().data.name || '',
    buyer_email: cart.buyer_email || UserOutlet.getValue().data.email || '',
    buyer_phone: cart.buyer_phone || UserOutlet.getValue().data.phone || '',
    buyer_zip: cart.buyer_zip || UserOutlet.getValue().data.zip || '',
    buyer_city: cart.buyer_city || UserOutlet.getValue().data.city || '',
    buyer_district:
      cart.buyer_district || UserOutlet.getValue().data.district || '',
    buyer_address:
      cart.buyer_address || UserOutlet.getValue().data.address || '',
  };

  const updateConfig = {
    ...cart,
    ...defaultUser,
  };

  // check payment valid value
  if (
    checkoutFormSpec.paymentSubTypes.indexOf(updateConfig.payment_subtype) < 0
  ) {
    updateConfig.payment_subtype = checkoutFormSpec.paymentSubTypes[0] || '';
  }
  // check logistic valid value
  if (
    checkoutFormSpec.logisticsTypes.indexOf(updateConfig.logistics_type) < 0
  ) {
    updateConfig.logistics_type = checkoutFormSpec.logisticsTypes[0] || '';
    updateConfig.logistics_subtype =
      checkoutFormSpec.logisticsSubTypes[updateConfig.logistics_type][0];
  }
  if (
    checkoutFormSpec.logisticsSubTypes?.[updateConfig.logistics_type].indexOf(
      updateConfig.logistics_subtype,
    ) < 0
  ) {
    updateConfig.logistics_subtype =
      checkoutFormSpec.logisticsSubTypes[updateConfig.logistics_type][0];
  }
  // checkout invoice valid value
  if (checkoutFormSpec.invoiceTypes.indexOf(updateConfig.invoice_type) < 0) {
    updateConfig.invoice_type = checkoutFormSpec.invoiceTypes[0] || '';
  }

  return {
    updateConfig,
    checkoutFormSpec,
  };
}

async function rebuild() {
  if (AppActionsOverride.rebuild) {
    return AppActionsOverride.rebuild(...arguments);
  }

  await req('https://api.netlify.com/build_hooks/615418bee44904a94bd7b4ab', {
    method: 'POST',
    data: {},
  });
}

async function fetchCustomResources(
  resource,
  {sort, keyword, filter, paging, extraQueries},
) {
  if (AppActionsOverride.fetchCustomResources) {
    return AppActionsOverride.fetchCustomResources(...arguments);
  }

  return null;
}

async function onLoginResult(err, result) {
  if (AppActionsOverride.onLoginResult) {
    return AppActionsOverride.onLoginResult(...arguments);
  }

  if (!err) {
    try {
      setLoading(true);
      const isAdmin = result.grp.split(':').indexOf('admin') !== -1;
      if (!isAdmin) {
        const queryKey = Config.jstoreVersion !== 'v1' ? 'owner' : 'id';
        const profile = await JStorage.fetchOneDocument('user_profile', {
          [queryKey]: UserOutlet.getValue().username,
        });
        const privateProfile = await User.getPrivateProfile();

        const labels = {
          pending: '申請中',
          reviewing: '審核中',
          valid: '有效',
          invalid: '停權',
          withdraw_reviewing: '退會申請中',
          withdraw: '退會',
        };

        if (['pending', 'invalid', 'withdraw'].indexOf(profile.status) > -1) {
          UserOutlet.update(null);
          window.localStorage.removeItem('token');
          Ant.message.warn(
            `您的帳號狀態為${labels[profile.status]}, 尚不允許登入`,
          );
          return;
        } else if (
          ['withdraw_reviewing', 'reviewing'].indexOf(profile.status) > -1
        ) {
          Ant.message.success(
            `您的帳號狀態為${labels[profile.status]}, 未來權益可能會有所調整`,
          );
        }

        UserOutlet.update({
          ...UserOutlet.getValue(),
          data: {
            ...profile,
            // email: privateProfile.email,
            // points: privateProfile.points,
            // provider: privateProfile.provider,
            // refer_by: privateProfile.refer_by,
            // credit_cards: privateProfile.credit_cards,
          },
        });

        await jwt.decodeToken(UserOutlet.getValue().token);
        tracker.login({user: UserOutlet.getValue()});
        await Cart.fetchCart({initial_clear: true, initial_raise: true});
      }
    } catch (ex) {
      console.warn('onLoginResult ex', ex);
    } finally {
      setLoading(false);
    }
  }
}

async function onTempLoginResult(err, result) {
  console.log('onTempLoginResult', err, result);
  if (!err) {
    try {
      setLoading(true);
      await Cart.fetchCart({initial_clear: true, initial_raise: true});
    } catch (ex) {
      console.warn('onTempLoginResult ex', ex);
    } finally {
      setLoading(false);
    }
  }
}

async function onAdminFormSubmit({
  path,
  collection,
  instance,
  extValues,
  formData,
  primaryKey,
}) {
  if (AppActionsOverride.onAdminFormSubmit) {
    return AppActionsOverride.onAdminFormSubmit(...arguments);
  }

  if (collection === 'product' && formData.archived) {
    formData.public = false;
  }

  // twpaa customization
  if (collection === 'supervisor_member') {
    if (formData.owner) {
      const user_profile = await JStorage.fetchOneDocument('user_profile', {
        owner: formData.owner,
      });

      if (user_profile) {
        formData.member_id = user_profile.member_id;
        formData.id_card_number = user_profile.id_card_number;
        formData.phone = user_profile.phone;
        formData.username = user_profile.name;
        formData.email = user_profile.email;
      }
    }
  }

  if (collection === 'user_profile') {
    await twpaaUpdateUserProfile(formData);
    if (instance?.status !== formData.status) {
      if (formData.status === 'reviewing') {
        twpaaSendBatchEmail({
          email_template_name: 'on-register-review',
          user_ids: [formData.owner],
          batch_size: 10,
        });
      } else if (formData.status === 'valid') {
        twpaaSendBatchEmail({
          email_template_name: 'on-register-success',
          user_ids: [formData.owner],
          batch_size: 10,
        });
      }
    }
    Ant.message.success('成功修改會員資料');
    setLoading(false);
    return true;
  }

  if (collection === 'committee_member_application') {
    if (instance?.status !== formData.status && formData.stats !== '審核中') {
      if (formData.action === '加入') {
        twpaaSendBatchEmail({
          email_template_name: 'committee-member-welcome',
          user_ids: [formData.owner],
          batch_size: 10,
          admin: {
            message: `
親愛的 ${formData.username} 專利師，您好！<br/>
感謝您對 ${formData.name} 的支持與關注，您已成功加入本委員會。<br/>
期待您參與委員會的各項工作與活動，並貢獻您的專業知識與經驗。<br/>
若您有任何問題或需要更多資訊，請隨時與我們聯繫。<br/>
再次感謝您加入委員會，期待與您的共同努力與合作。<br/><br/>
敬祝<br/>
工作順利，萬事如意。
          `,
          },
        });
      }
    }
  }

  if (collection === 'event_registration') {
    if (!instance) {
      const user_profile = await JStorage.fetchOneDocument('user_profile', {
        owner: formData.owner,
      });

      if (user_profile) {
        formData.member_id = user_profile.member_id;
        formData.id_card_number = user_profile.id_card_number;
        formData.certificate_number = user_profile.certificate_number;
        formData.agency_name = user_profile.agency_name;
        formData.phone = user_profile.phone;
        formData.name = user_profile.name;
        formData.email = user_profile.email;
        formData.contact_address = user_profile.contact_address;
      }

      formData.unique_number = uuid4();
    }
  }

  return false;
}

async function onAfterAdminFormSubmit(
  {path, collection, instance, extValues, formData, primaryKey},
  updatedInstance,
) {
  if (AppActionsOverride.onAfterAdminFormSubmit) {
    return AppActionsOverride.onAfterAdminFormSubmit(...arguments);
  }

  if (
    path.indexOf('/admin/landing') > -1 ||
    path.indexOf('/admin/product_category') > -1 ||
    path.indexOf('/admin/article_category') > -1 ||
    path.indexOf('/admin/sales_event_category') > -1 ||
    path.indexOf('/admin/config') > -1 ||
    path.indexOf('/admin/about') > -1 ||
    path.indexOf('/admin/navbar_config') > -1 ||
    path.indexOf('/admin/footer_config') > -1 ||
    path.indexOf('/admin/customer_support_config') > -1 ||
    path.indexOf('/admin/twpaa_about_category') > -1 ||
    path.indexOf('/admin/twpaa_info_category') > -1
  ) {
    await JStorage.cacheDocuments('site', {}, null, null, null, undefined, {
      key: 'rev-site-cache.json',
    });
  }

  if (collection === 'return_request') {
    if (instance.status !== formData.status) {
      if (formData.status === 'return_applied') {
        try {
          await Cart.returnConfirm(instance.id, 'return_applied');
        } catch (ex) {
          console.warn(ex);
        }
      }

      if (formData.status === 'return_completed') {
        try {
          await Cart.returnConfirm(instance.id, 'return_completed');
        } catch (ex) {
          console.warn(ex);
        }
      }
    }

    if (
      instance.status !== formData.status ||
      instance.data?.staff_note !== formData.data?.staff_note
    ) {
      try {
        let private_profile = await JStorage.fetchOneDocument(
          'private_profile',
          {
            owner: formData.owner,
          },
        );

        await Admin.sendReturnEmail(private_profile.email, formData);
      } catch (ex) {
        console.warn(ex);
      }
    }
  }

  return null;
}

function getReurl({title, description, image, redirectUrl}) {
  if (AppActionsOverride.getReurl) {
    return AppActionsOverride.getReurl(...arguments);
  }

  return `${Config.apiHost}/misc/reurl?title=${title}&image=${image}${
    description ? `&description=${description}` : ''
  }&redirect_url=${redirectUrl}`;
}

async function sendCustomerSupport(values) {
  if (AppActionsOverride.sendCustomerSupport) {
    return AppActionsOverride.sendCustomerSupport(...arguments);
  }

  return JStorage.createDocument('customer_support', values, {anonymous: true});
}

async function fetchSessions(timestrPrefix = '2023-03') {
  //FIXME: handle sort, page's offset and limit
  return JStorage.fetchDocuments(
    'product',
    {
      public: true,
      'session.date': {$regex: timestrPrefix},
      type: {$ne: 'group_root'},
    },
    ['session.date'],
    null,
    null,
    {
      anonymous: true,
    },
  );
}

async function twpaaApprovePendingRegistration({id}) {
  return ApiUtil.req(
    `${Config.apiHost}/twpaa/pending_registration/approve?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {id},
    },
  );
}

async function twpaaDeleteUser(user_id) {
  return ApiUtil.req(
    `${Config.apiHost}/twpaa/delete_user?token=${
      UserOutlet.getValue().token
    }&user_id=${user_id}`,
    {
      method: 'POST',
    },
  );
}

async function twpaaCreateEventRregistration({event_id, data}) {
  if (UserOutlet.getValue()?.token) {
    return ApiUtil.req(
      `${Config.apiHost}/twpaa/event_registration?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        data: {
          event_id,
          data,
        },
      },
    );
  } else {
    return ApiUtil.req(`${Config.apiHost}/twpaa/event_registration`, {
      method: 'POST',
      data: {
        event_id,
        data,
      },
    });
  }
}

async function twpaaApproveEventRegistration({id, data}) {
  return ApiUtil.req(
    `${Config.apiHost}/twpaa/event_registration/approve?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {id, data},
    },
  );
}

async function twpaaUpdateUserProfile(data) {
  return ApiUtil.req(
    `${Config.apiHost}/twpaa/user_profile/update?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {data},
    },
  );
}

async function twpaaFetchEventList({query, paging}) {
  const resp = await ApiUtil.req(`${Config.apiHost}/twpaa/event-list`, {
    method: 'POST',
    data: {
      query,
      paging,
    },
  });
  return {
    ...resp,
    results: resp.results.map((it) => {
      return {
        ...it,
        id: it._id.$oid,
      };
    }),
  };
}

async function twpaaSendBatchEmail(data) {
  const {email_template_name, emails, user_ids, batch_size = 10, admin} = data;
  const resp = await ApiUtil.req(
    `${Config.apiHost}/twpaa/send_batch_email?token=${
      getOutlet('user').getValue().token
    }`,
    {
      method: 'POST',
      data: {
        email_template_name,
        emails,
        user_ids,
        batch_size,
        admin,
      },
    },
  );
  return resp;
}

async function twpaaAssignStudyHours({event_id, data = {}}) {
  const resp = await ApiUtil.req(
    `${Config.apiHost}/twpaa/assign_study_hours?token=${
      getOutlet('user').getValue().token
    }`,
    {
      method: 'POST',
      data: {
        event_id,
        data,
      },
    },
  );
  return resp;
}

async function twpaaAdminForceResetUserPassword({owner, password}) {
  const resp = await ApiUtil.req(
    `${Config.apiHost}/user/admin/force-reset-user-password?token=${
      getOutlet('user').getValue().token
    }`,
    {
      method: 'POST',
      data: {
        owner,
        password,
      },
    },
  );
  return resp;
}

async function twpaaFetchEventRegistrationListByUser({paging}) {
  const resp = await ApiUtil.req(
    `${Config.apiHost}/twpaa/event_registration_list_by_user?token=${
      getOutlet('user').getValue().token
    }`,
    {
      method: 'POST',
      data: {
        paging,
      },
    },
  );
  return resp;
}

async function twpaaFetchLatestCommitteeMember({name, role}) {
  const resp = await ApiUtil.req(
    `${Config.apiHost}/twpaa/committee_member/latest?token=${
      getOutlet('user').getValue().token
    }`,
    {
      method: 'POST',
      data: {
        name,
        role,
      },
    },
  );
  return resp;
}

async function twpaaPostCommitteeMemberRequest(data) {
  const resp = await ApiUtil.req(
    `${Config.apiHost}/twpaa/committee_member/request?token=${
      getOutlet('user').getValue().token
    }`,
    {
      method: 'POST',
      data: {
        data,
      },
    },
  );
  return resp;
}

async function customAdminJStorageFetch(...args) {
  const [collection, query, sorting, paging, projection] = args;

  if (collection === 'product') {
    const {total} = await JStorage.fetchDocuments(
      collection,
      query,
      sorting,
      paging,
      {_id: 1},
    );
    const results = await JStorage.aggregateDocuments('product', [
      {
        $match: query,
      },
      {
        $sort: sorting.reduce((acc, it) => {
          let sign = 1;
          if (it.indexOf('-') === 0) {
            it = it.slice(1);
            sign = -1;
          } else if (it.indexOf('+') === 0) {
            it = it.slice(1);
          }
          acc[it] = sign;
          return acc;
        }, {}),
      },
      {
        $skip: paging.offset,
      },
      {
        $limit: paging.limit,
      },
      {
        $addFields: {
          event_id: {
            $toString: '$_id',
          },
        },
      },
      {
        $lookup: {
          from: 'event_registration',
          localField: 'event_id',
          foreignField: 'event_id',
          as: 'evt_regs',
        },
      },
      {
        $project: {
          'evt_regs.product': 0,
        },
      },
    ]);
    return {
      total,
      results: results.map((it) => {
        return {
          ...it,
          id: it._id.$oid,
          reg_cnt: it.evt_regs.filter((itt) => {
            return itt.status !== 'failure';
          }).length,
        };
      }),
    };
  } else if (collection === 'event_registration') {
    const {total} = await JStorage.fetchDocuments(
      collection,
      query,
      sorting,
      paging,
      {_id: 1},
    );
    const results = await JStorage.aggregateDocuments('event_registration', [
      {
        $match: query,
      },
      {
        $sort: sorting.reduce((acc, it) => {
          let sign = 1;
          if (it.indexOf('-') === 0) {
            it = it.slice(1);
            sign = -1;
          } else if (it.indexOf('+') === 0) {
            it = it.slice(1);
          }
          acc[it] = sign;
          return acc;
        }, {}),
      },
      {
        $skip: paging.offset,
      },
      {
        $limit: paging.limit,
      },
      {
        $addFields: {
          event_id: {
            $convert: {
              input: '$event_id',
              to: 'objectId',
              onError: '$event_id',
              onNull: '',
            },
          },
        },
      },
      {
        $lookup: {
          from: 'product',
          localField: 'event_id',
          foreignField: '_id',
          as: 'product',
        },
      },
    ]);
    return {
      total,
      results: results.map((it) => {
        return {
          ...it,
          id: it._id.$oid,
          product: it.product[0],
        };
      }),
    };
  } else if (collection === 'site') {
    return JStorage.fetchDocuments(
      collection,
      {
        name: {
          $nin: [
            'customer_support_config',
            'sales_event_category',
            'product_category',
            'about',
            'config',
            'footer_config',
          ],
        },
      },
      sorting,
      paging,
      projection,
    );
  }

  return JStorage.fetchDocuments(
    collection,
    query,
    sorting,
    paging,
    projection,
  );
}

export {
  delay,
  setLoading,
  L,
  F,
  navigate,
  adminCanAccess,
  getDefaultCheckoutFormSpec,
  clientJStorageFetch,
  fetchCustomResources,
  onLoginResult,
  onTempLoginResult,
  onAdminFormSubmit,
  onAfterAdminFormSubmit,
  onCartLoaded,
  rebuild,
  getReurl,
  gtag,
  fbq,
  sendCustomerSupport,
  fetchSessions,
  twpaaApprovePendingRegistration,
  twpaaDeleteUser,
  twpaaCreateEventRregistration,
  twpaaApproveEventRegistration,
  twpaaUpdateUserProfile,
  twpaaFetchEventList,
  twpaaSendBatchEmail,
  twpaaAssignStudyHours,
  twpaaAdminForceResetUserPassword,
  twpaaFetchEventRegistrationListByUser,
  twpaaFetchLatestCommitteeMember,
  twpaaPostCommitteeMemberRequest,
  customAdminJStorageFetch,
};
