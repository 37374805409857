import React from 'react';
import {getOutlet, useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {loadAllUsers} from '../Generators/AdminResource/AdminSelectUserWidget';

const ProductsOutlet = getOutlet('products');
const ArticlesOutlet = getOutlet('articles');

export default function useAdminLayoutInit({location}) {
  useOutlet('allUsers');

  React.useEffect(() => {
    // trigger only once
    async function fetchAllProducts() {
      try {
        let resp = await JStorage.fetchAllDocuments('product', {}, null, {
          name: 1,
          price: 1,
        });
        ProductsOutlet.update(resp);
      } catch (err) {
        console.warn(err);
      }
    }

    async function fetchAllArticles() {
      try {
        let resp = await JStorage.fetchAllDocuments(
          'Article_Default',
          {},
          null,
          {
            title: 1,
          },
        );

        ArticlesOutlet.update(resp);
      } catch (err) {
        console.warn(err);
      }
    }

    console.log('DBGGG useAdminResourceInit hook triggered');
    fetchAllProducts();
    fetchAllArticles();
    loadAllUsers();
  }, []);

  React.useEffect(() => {
    // trigger when location change
  }, [location]);

  return {
    extraElement: null,
  };
}
