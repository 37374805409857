import React from 'react';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Config from '../../data.json';

function getInitialValue({pageContext, location}) {
  if (pageContext.footer && pageContext.footer?.type === 'none') {
    return false;
  } else if (
    Config.patToHideSiteFooter &&
    new RegExp(Config.patToHideSiteFooter).test(location.pathname)
  ) {
    return false;
  } else {
    return true;
  }
}

export default function useSiteFooter(props) {
  const {location, pageContext} = props;
  const [showFooter, setShowFooter] = React.useState(
    getInitialValue({pageContext, location}),
  );
  const params = qs.parse(location.search);

  React.useEffect(() => {
    async function check() {
      if (location.pathname.indexOf('/page-preview') === 0) {
        const pages = await JStorage.fetchAllDocuments('page', {}, null, null, {
          anonymous: true,
        });
        const customPage = (pages || []).find((p) => p.id === params.id);
        if (customPage.footer && customPage.footer.type === 'none') {
          setShowFooter(false);
        } else if (params.no_footer) {
          setShowFooter(false);
        }
      } else {
        if (pageContext.footer && pageContext.footer?.type === 'none') {
          setShowFooter(false);
        } else if (
          Config.patToHideSiteFooter &&
          new RegExp(Config.patToHideSiteFooter).test(location.pathname)
        ) {
          setShowFooter(false);
        } else {
          setShowFooter(true);
        }
      }
    }

    check();
  }, [location.pathname, params.id, pageContext.footer]);

  return {showFooter};
}
