import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as NavUtil from '../../Utils/NavUtil';
import Theme from '../../Theme';
import * as AppActions from '../../AppActions';

export default function SiteFooter(props) {
  useOutlet('navbarConfig');
  const navItems = NavUtil.getNavItems(props);

  return (
    <Wrapper>
      <div className="content">
        <div className="row">
          <div className="left">
            <div className="logo-box">
              <img src="/images/site-logo.png" alt="Logo" />
              <div className="logo-text">
                <div className="main">社團法人中華民國專利師公會</div>
                <div className="sub">Taiwan Patent Attorneys Association</div>
              </div>
            </div>
            <div style={{marginTop: 30}}>
              <div>地址: 110台北市信義區忠孝東路五段508號11樓之1</div>
              <div>電話: +886-2-2701-1990</div>
              <div>傳真: +886-2-2701-0799</div>
              <div>信箱: mail@twpaa.org.tw</div>
            </div>
          </div>
          <div className="right">
            {navItems.map((it, idx) => {
              return (
                <div key={it.name} className="nav-items">
                  <h3>{it.name}</h3>
                  <ul>
                    {(it.entries || []).map((itt, idxx) => {
                      return (
                        <li key={itt.name}>
                          <a
                            href={itt.path}
                            onClick={(e) => {
                              e.preventDefault();
                              AppActions.navigate(itt.path);
                            }}>
                            {itt.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>

        <div className="bottom">
          <div>
            本網站相關資料仍以本會書面正式文件為準 © 2025 TWPAA. All Rights
            Reserved. 版權所有 社團法人中華民國專利師公會
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-top: 2px solid #ddd;
  padding: 40px 20px;

  .content {
    max-width: 1200px;
    margin: 0 auto;

    .row {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      .left {
        flex: 1 0 300px;
      }

      .right {
        display: none;
        /*
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;

        @media (max-width: 768px) {
          display: none;
        }
        */
      }
    }

    .bottom {
      margin-top: 40px;
      border-top: 2px solid ${Theme.colors.main};
      padding: 30px 0;
    }
  }

  .logo-box {
    display: flex;
    gap: 10px;
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }

  .nav-items {
    h3 {
      margin-bottom: 10px;
    }

    ul {
      padding: 0px;
      & > li {
        list-style-type: none;
        display: block;
        margin-bottom: 5px;
        a {
          color: #666;
        }
      }
    }
  }
`;
