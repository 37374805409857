import React from 'react';
import styled from 'styled-components';
import {Button, Form, Input, message} from 'antd';
import * as AppActions from '../../AppActions';
import * as User from 'rev.sdk.js/Actions/User';
import {ExclamationCircle as ExcIcon} from '@styled-icons/bootstrap/ExclamationCircle';

const LOGIN_TYPE = {
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  LINE: 'LINE',
  NORMAL: 'NORMAL',
};

function LoginForm(props) {
  const actions = AppActions;

  const {
    admin,
    formTitle = '',
    adminFormTitle = '',
    onSucceed = () => 0,
    closeModal,
    renderExtra = null,
  } = props;

  const onFinish = async (values) => {
    const {username, password} = values;

    await actions.setLoading(true);
    try {
      await User.login({username, password}, admin);
      onSucceed();
    } catch (ex) {
      console.log('EX', ex);
      message.error('登入失敗，請重新嘗試');
    } finally {
      await actions.setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const title = React.useMemo(() => {
    let ele = null;
    if (admin) {
      if (!adminFormTitle || typeof adminFormTitle === 'string') {
        ele = <h2>{adminFormTitle || 'ADMIN 登入'}</h2>;
      } else {
        ele = adminFormTitle;
      }
    } else {
      if (!formTitle || typeof formTitle === 'string') {
        ele = <h2>{formTitle || '會員登入'}</h2>;
      } else {
        ele = formTitle;
      }
    }

    return ele;
  }, [admin, adminFormTitle, formTitle]);

  return (
    <Wrapper>
      {title}

      {!admin && (
        <ul className="notices">
          <li>
            <ExcIcon size={30} />
            <div>
              帳號為身分證字號，預設密碼為西元出生年月日（例如：20250101）。
            </div>
          </li>
          <li>
            <ExcIcon size={30} />
            <div>
              若您持有專利師證書但尚未加入本會，可參閱{' '}
              <a href="/member/notice/" target="_blank">
                會員入會須知
              </a>
               說明，申請加入成為本會會員。
            </div>
          </li>
          <li>
            <ExcIcon size={30} />
            <div>忘記密碼請聯繫本會秘書處02-2701-1990分機9重新設定。</div>
          </li>
        </ul>
      )}

      <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: '請輸入帳號!',
            },
          ]}
          style={{marginBottom: 10}}>
          <Input className="input-style" placeholder="＊帳號" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: '請輸入密碼!',
            },
          ]}
          style={{marginBottom: 26}}>
          <Input.Password className="input-style" placeholder="＊密碼" />
        </Form.Item>

        <Form.Item style={{marginBottom: 26}}>
          <Button className="button-style" type="primary" htmlType="submit">
            登入
          </Button>
        </Form.Item>

        {!admin && (
          <Form.Item style={{marginBottom: 26}}>
            <Button
              type="link"
              style={{width: '100%'}}
              onClick={() => {
                closeModal();
                actions.navigate('/member/register/');
              }}>
              申請入會
            </Button>
          </Form.Item>
        )}
      </Form>

      {renderExtra && renderExtra({...props})}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > h2 {
    margin-bottom: 20px;
    font-size: 25px;
    color: #000000;
    letter-spacing: 3px;
    line-height: 25px;
    text-align: center;
  }

  & .input-style {
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 10px 15px;
    height: 45px;
  }

  & .button-style {
    box-shadow: 0 5px 8px 0 lightgray;
    border-radius: 10px;
    padding: 9px;
    width: 100%;
    height: 40px;
    font-weight: 500;
    font-size: 15px;
  }

  & ul.notices {
    padding: 0;
    margin: 0;
    list-style-type: none;
    & > * {
      display: flex;
      gap: 8px;
      margin-bottom: 10px;
      & > *:first-child {
        flex: 0 0 18px;
      }
    }
  }
`;

export default LoginForm;
export {LOGIN_TYPE};
