import React from 'react';
import styled from 'styled-components';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import NavLink from '../NavLink';
import MobileNavDrawer from './MobileNavDrawer';
import * as NavUtil from '../../Utils/NavUtil';
import {NavItemsContent} from './NavItemsContent';
import * as AppActions from './../../AppActions';
import useGlobalOutlets from '../../Hooks/useGlobalOutlets';
import Theme from '../../Theme';
import {Menu as MenuIcon} from '@styled-icons/boxicons-regular/Menu';
import {Language as LanguageIcon} from '@styled-icons/material/Language';
import EngNavItems from './eng-nav-items.json';

const Config = require('../../../data.json');
const F = AppActions.F;

function SiteNavBar(props) {
  useGlobalOutlets();

  const {location} = props;
  useOutlet('navbarConfig');
  const showLoginModal = useOutletSetter('login-modal');
  const [mobileNavVisible, setMobileNavVisible] = React.useState(false);
  const navItems = EngNavItems;

  React.useEffect(() => {
    setMobileNavVisible(false);
  }, [location?.pathname]);

  const mobileNavItemContent = (
    <div className="nav-items">
      {navItems.map((navItem, idx) => {
        const selected = NavUtil.isNavLinkSelected(navItem);

        return (
          <React.Fragment key={idx}>
            <NavLink
              {...NavUtil.getNavLinkExtraProps(navItem)}
              to={navItem.path}
              className={selected ? `default selected ` : `default`}>
              {F(navItem, 'name')}
            </NavLink>
            {(navItem.entries || []).map((item, i) => (
              <NavLink
                {...NavUtil.getNavLinkExtraProps(item)}
                to={item.path}
                key={`item-${i}`}
                className={`default-child`}>
                {F(item, 'name')}
              </NavLink>
            ))}
          </React.Fragment>
        );
      })}
    </div>
  );

  return (
    <Wrapper id="rev-SiteNavBar">
      <div className="maxwidth-container">
        <div className="logo-box">
          <NavLink to="/" loading={800}>
            <img
              src="/images/site-logo.png"
              alt="Logo"
              style={{
                height: 50,
                objectFit: 'contain',
              }}
            />
          </NavLink>
          <div className="logo-text" onClick={() => AppActions.navigate('/')}>
            <div className="main">社團法人中華民國專利師公會</div>
            <div className="sub">Taiwan Patent Attorneys Association</div>
          </div>
        </div>
        <NavItemsContent
          navItems={[]}
          showLoginModal={showLoginModal}
          setMobileNavVisible={setMobileNavVisible}
        />
      </div>

      <div className="twpaa-top-nav-container-mobile">
        <button onClick={() => setMobileNavVisible(!mobileNavVisible)}>
          <MenuIcon size={24} />
        </button>
        <div style={{flex: 1}} />
        <NavLink to="/" loading={800}>
          <img
            src="/images/site-logo.png"
            alt="Logo"
            style={{
              height: 50,
              objectFit: 'contain',
            }}
          />
        </NavLink>
        <div style={{flex: 1}} />
        <button onClick={() => AppActions.navigate('/')}>
          <LanguageIcon size={24} />
          中文
        </button>
      </div>

      <div className="submenu-container">
        <div className="content">
          {navItems.map((navItem, idx) => {
            const selected = NavUtil.isNavLinkSelected(navItem);

            if (navItem.entries) {
              return (
                <DropDownMenu key={idx}>
                  <button className="dropdown-btn" key={idx}>
                    {F(navItem, 'name')}
                  </button>

                  <div className="dropdown-content">
                    {(navItem.entries || []).map((item, i) => (
                      <NavLink
                        {...NavUtil.getNavLinkExtraProps(navItem)}
                        to={item.path}
                        key={i}>
                        {F(item, 'name')}
                      </NavLink>
                    ))}
                  </div>
                </DropDownMenu>
              );
            }

            return (
              <NavLink
                {...NavUtil.getNavLinkExtraProps(navItem)}
                to={navItem.path}
                key={idx}
                className={selected ? `default-btn selected` : `default-btn`}>
                {F(navItem, 'name')}
              </NavLink>
            );
          })}
        </div>
      </div>

      <MobileNavDrawer
        visible={mobileNavVisible}
        setVisible={setMobileNavVisible}
        {...props}>
        {mobileNavItemContent}
      </MobileNavDrawer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  background-color: white;
  top: 0px;
  left: 0px;
  width: 100%;
  // height: var(--topNavBarHeight);
  // padding: 0px 40px;

  // display: flex;
  // align-items: center;

  z-index: 101;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition: 200ms;

  &:hover {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.16);
  }

  & > .maxwidth-container-mobile {
    display: none;
  }

  & > .maxwidth-container {
    display: flex;
    align-items: center;
    max-width: var(--contentMaxWidth);
    width: 100%;
    margin: 0 auto;
    padding: 12px 40px;
  }

  & > .maxwidth-container > .logo-box {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > .logo-text {
      margin-left: 8px;
      .main {
        font-size: 20px;
      }
      .sub {
        font-size: 14px;
        color: #aaa;
      }
    }
  }

  & > .maxwidth-container > .nav-items {
    flex: 1;
    display: flex;
    align-items: center;

    & > * {
      &:hover {
        color: var(--primaryColor);
      }

      &.default {
        border-bottom: 6px solid transparent;
        margin: 0px 25px;
        padding: 19px 5px;
        color: #707070;
      }

      &.selected {
        border-bottom: 6px solid var(--primaryColor);
        color: #242424;
      }
    }
  }

  & > .maxwidth-container > .menu-btn-box {
    padding: 8px;
    cursor: pointer;
  }

  & .nav-round-btn-box {
    display: flex;
    align-items: center;
    border-radius: 60px;
    padding: 6px;
    background-color: transparent;
    color: ${Config.themeColor};
    outline: none;
    border: none;
    border: 1px solid ${Config.themeColor};
    box-shadow: none;
    transition: all 200ms;
    cursor: pointer;
    margin-left: 6px;

    & > * {
      margin: 0 3px;
    }
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    }
    &:active {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }
  }

  & .nav-icon-btn-box {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    }
    &:active {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }
  }

  & > .submenu-container {
    width: 100%;
    background-color: white;
    border-top: 1px solid #ddd;
    & > .content {
      & > a {
        color: ${Theme.colors.main};
      }

      max-width: var(--contentMaxWidth);
      margin: 0 auto;
      padding: 12px 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .dropdown-btn,
      .default-btn {
        padding: 0 10px;
      }
    }
  }

  & .twpaa-nav-btn {
    border: none;
    background: none;
    margin-right: 10px;
    cursor: pointer;
  }

  & > .twpaa-top-nav-container-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    & .twpaa-desktop-only {
      display: none;
    }

    & > .maxwidth-container {
      display: none;
    }

    & > .twpaa-top-nav-container-mobile {
      display: flex;
      & button {
        width: 48px;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 16px;

    & .nav-round-btn-box {
      justify-content: center;
    }

    & > .maxwidth-container {
      padding: 0px 20px;
      .logo-box {
        .logo-text {
          .main {
            font-size: 16px;
          }
          .sub {
            display: none;
          }
        }
      }
    }

    & > .submenu-container {
      display: none;
    }
  }
`;

const DropDownMenu = styled.div`
  position: relative;
  display: inline-block;

  & > .dropdown-btn {
    border: none;
    background-color: transparent;
    padding: 0px 10px;
    cursor: pointer;
    color: ${Theme.colors.main};
  }

  & > .dropdown-content {
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 101;
    background-color: #ffffff;
    min-width: 160px;
    overflow: hidden;

    transition: all 150ms linear 100ms;
    height: 0px;
    opacity: 0;

    & > a {
      display: block;
      line-height: 2.2;
      padding: 2px 6px;
    }
  }

  &:hover > .dropdown-btn {
    color: var(--secondColor);
  }

  &:hover > .dropdown-content {
    height: auto;
    padding: 15px 5px;
    opacity: 1;

    & > a:hover {
      color: var(--secondColor);
    }
  }
`;

export default SiteNavBar;
