import React from 'react';
import qs from 'query-string';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Config from '../../data.json';

function getInitialValue({pageContext, location}) {
  if (pageContext.navbar && pageContext.navbar?.type !== 'global') {
    return false;
  } else if (
    Config.patToHideSiteNavBar &&
    new RegExp(Config.patToHideSiteNavBar).test(location.pathname)
  ) {
    return false;
  } else {
    return true;
  }
}

export default function useSiteNavBar(props) {
  const {location, pageContext} = props;
  const [showNavbar, setShowNavbar] = React.useState(
    getInitialValue({pageContext, location}),
  );
  const params = qs.parse(location.search);

  React.useEffect(() => {
    async function checkPreviewData() {
      if (location.pathname.indexOf('/page-preview') === 0) {
        const pages = await JStorage.fetchAllDocuments('page', {}, null, null, {
          anonymous: true,
        });
        const customPage = (pages || []).find((p) => p.id === params.id);
        if (customPage.navbar && customPage.navbar.type !== 'global') {
          setShowNavbar(false);
        } else if (params.no_navbar) {
          setShowNavbar(false);
        }
      } else {
        if (pageContext.navbar && pageContext.navbar?.type !== 'global') {
          setShowNavbar(false);
        } else if (
          Config.patToHideSiteNavBar &&
          new RegExp(Config.patToHideSiteNavBar).test(location.pathname)
        ) {
          setShowNavbar(false);
        } else {
          setShowNavbar(true);
        }
      }
    }

    checkPreviewData();
  }, [location.pathname, params.id, pageContext.navbar]);

  return {showNavbar};
}
