import React from 'react';
import styled from 'styled-components';
import {useOutlet, getOutlet} from 'reconnect.js';
import TwpaaCategoryTree from '../../Components/TwpaaCategoryTree';
import * as AppActions from '../../AppActions';
import * as User from 'rev.sdk.js/Actions/User';

const ProfileCategory = {
  items: [
    {
      name: '會籍登記資料',
      display: '會籍登記資料',
      url: '/profile/',
    },
    {
      name: '會費繳納紀錄',
      display: '會費繳納紀錄',
      url: '/profile/member-fee/',
    },
    {
      name: '活動報名紀錄',
      display: '活動報名紀錄',
      url: '/profile/evt-reg/',
    },
    {
      name: '進修時數紀錄',
      display: '進修時數紀錄',
      url: '/profile/study-hour/',
    },
    {
      name: '會員服務',
      display: '會員服務',
      items: [
        {
          name: '專題演講講義',
          display: '專題演講講義',
          url: '/profile/speech-keynotes/',
        },
        {
          name: '徵才服務登記',
          display: '徵才服務登記',
          url: '/profile/job-request/',
        },
        {
          name: '委員會申請',
          display: '委員會申請',
          url: '/profile/committee-request/',
        },
        {
          name: '行政文件下載',
          display: '行政文件下載',
          url: '/profile/adm-docs/',
        },
        {
          name: '其他檔案下載',
          display: '其他檔案下載',
          url: '/profile/etc-files/',
        },
        {
          name: '登入密碼修改',
          display: '登入密碼修改',
          onClick: () => {
            console.log('test');
            getOutlet('reset-password-modal').update(true);
          },
        },
      ],
    },
    {
      name: '專利審查實務溝通會意見表',
      display: '專利審查實務溝通會意見表',
      url: '/profile/feedback-form-request/',
    },
    {
      name: '登出',
      display: '登出',
      onClick: async () => {
        AppActions.setLoading(true);
        User.logout();
        await AppActions.delay(500);
        AppActions.setLoading(false);
        AppActions.navigate('/');
      },
    },
  ],
};

export default function ProfileLayout(props) {
  const [user] = useOutlet('user');
  return (
    <Wrapper>
      <div className="main">
        <div className="nav">
          <TwpaaCategoryTree root={ProfileCategory} location={props.location} />
        </div>
        {user ? (
          <div className="content">{props.children}</div>
        ) : (
          <div className="content">
            <h3 style={{paddingTop: 80, textAlign: 'center'}}>請先登入</h3>
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  min-height: var(--contentMinHeight);

  & > .main {
    max-width: 1120px;
    margin: 20px auto;
    display: flex;
    align-items: flex-start;
    gap: 20px;

    & > .nav {
      margin-top: 60px;
      width: 240px;
    }

    & > .content {
      flex: 1;
      min-height: 300px;
      padding: 0 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    & > .main {
      flex-direction: column;
      align-items: stretch;
      & > .nav {
        width: 100%;
        order: 10;
      }
    }
  }
`;
