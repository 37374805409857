import React from 'react';
import styled from 'styled-components';
import {ChevronUp} from '@styled-icons/boxicons-regular/ChevronUp';
import {ChevronDown} from '@styled-icons/boxicons-regular/ChevronDown';
import * as AppActions from '../../AppActions';
import Theme from '../../Theme';

function CategoryNode(props) {
  const {node, location} = props;
  const [expand, setExpand] = React.useState(false);

  const isUrlSelected = React.useCallback(
    (url) => {
      const [path, query] = (url || '').split('?');
      if (path === decodeURIComponent(location.pathname)) {
        if (!query) {
          return true;
        } else if (location.search === `?${query}`) {
          return true;
        }
      }
      return false;
    },
    [location.pathname, location.search],
  );

  React.useEffect(() => {
    for (const it of node.items || []) {
      if (isUrlSelected(it.url)) {
        setExpand(true);
      }
    }
    return false;
  }, [isUrlSelected, node]);

  if (!(node.items?.length > 0)) {
    const selected = isUrlSelected(node.url);
    return (
      <NodeWrapper>
        <div className="title">
          <h3
            style={selected ? {color: Theme.colors.main} : {}}
            onClick={() => {
              if (node.onClick) {
                node.onClick();
                return;
              }
              if (node.url) {
                AppActions.navigate(node.url);
              }
            }}>
            {node.display}
          </h3>
        </div>
      </NodeWrapper>
    );
  }

  return (
    <NodeWrapper expand={expand}>
      <div
        className="title"
        onClick={() => {
          setExpand(!expand);
        }}>
        <h3>{node.display}</h3>
        {expand ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </div>
      <div className="sub-items">
        {node.items.map((it) => {
          const selected = isUrlSelected(it.url);
          return (
            <div
              key={it.name}
              style={{
                padding: '10px 0 10px 30px',
                borderTop: '1px solid #ccc',
                cursor: 'pointer',
                color: selected ? Theme.colors.main : 'black',
              }}
              onClick={() => {
                if (it.onClick) {
                  it.onClick();
                  return;
                }
                if (it.url) {
                  AppActions.navigate(it.url);
                }
              }}>
              {it.display}
            </div>
          );
        })}
      </div>
    </NodeWrapper>
  );
}

const NodeWrapper = styled.div`
  border-top: 1px solid #ccc;

  & > .title {
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    h3 {
      margin: 0;
      padding: 10px 0;
      flex: 1;
      font-size: 15px;
    }
  }

  & > .sub-items {
    transition: all 200ms;
    opacity: ${(props) => (props.expand ? '1' : '0')};
    max-height: ${(props) => (props.expand ? '10000px' : '0px')};
    pointer-events: ${(props) => (props.expand ? 'auto' : 'none')};
  }
`;

export default function TwpaaCategoryTree(props) {
  const {root, location} = props;

  return (
    <div>
      {root.items?.length > 0 &&
        root.items.map((node) => {
          return (
            <CategoryNode node={node} key={node.name} location={location} />
          );
        })}
    </div>
  );
}
